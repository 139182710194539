import React, { useLayoutEffect, useContext } from 'react';
import Layout from '../components/layout';
import { ThemeContext } from '../providers/theme';
import Products from '../components/Products';
import InfoCards from '../components/InfoCard';
import InfoContainer from '../components/InfoContainer';
import SEO from '../components/seo';
import { handleProducts } from '../utils/handleProducts';
import { graphql, useStaticQuery } from 'gatsby';
import Breadcrumbs from '../components/Breadcrumbs';

function WhiteWine() {
  const { setTheme } = useContext(ThemeContext);

  useLayoutEffect(() => {
    setTheme('whiteWine');
  }, []);

  const data = handleProducts(
    useStaticQuery(graphql`
      {
        allSitePage(
          filter: {
            context: {
              productType: { eq: "hvidvin" }
              isProduct: { eq: "true" }
            }
          }
        ) {
          edges {
            node {
              path
              context {
                productTitle
                productPrice
                productOldprice
                productLink
                productImage
                productDescription
                productCategory
                productAvailable
                productType
              }
            }
          }
        }
      }
    `)
  );

  return (
    <React.Fragment>
      <SEO
        title={'Hvidvin'}
        description={
          'Find den helt rigtige hvidvin. Vi samler udvalg i samarbejde med en håndfuld af landets vinforhandlere, for at gøre det nemt for dig at finde den helt rette flaske'
        }
      />
      <Layout themeColor="#d4c52d" title="Hvidvin">
        <Products type="hvidvin" data={data} />
        <InfoCards
          firstColumn={{
            title: 'Hvidvin til enhver smag',
            text:
              'På vinkammeret.dk finder du et stort udvalg af hvidvin, hvad enten du er til tør hvidvin eller sød hvidvin. Når du har fundet den vin du ønsker, bliver du sendt videre til forhandleren. Vi går meget op i, at kunne præsentere så mange forskellige vine som muligt, så du kan finde den hvidvin, som passer allerbedst til dig og dit måltid.'
          }}
          secondColumn={{
            title: 'Mange forskellige hvidvine',
            text:
              'Der findes et hav af forskellige slags hvidvine, som er lavet af forskellige druesorter, hvoraf de mest populære sorter er Chardonnay, Riesling, Pinot Gris / Pinot Grigio og Sauvignon Blanc.'
          }}
          thirdColumn={{
            title: 'Hvidvin fra forskellige lande',
            text:
              'Der produceres hvidvin i mange forskellige lande, det er blandt andet muligt at købe vin fra forskellige lande i Europa, New Zealand, Australien, Chile, Sydafrika, USA og Argentina. '
          }}
        />
        <InfoContainer
          title={'Vidste du...'}
          text={
            'Hvidvin kan produceres af enten røde eller grønne druer, forskellen ligger i om druernes skal er med i processen eller ej. Hvis druens skal ikke er med producerer man hvidvin, hvis skallen derimod er med, så producerer man rødvin.. Mange tror også, at hvidvinen har fået sit navn efter dens farve, men i virkeligheden er hvidvin ikke hvidt, men kan enten være klar, have en gullig farve eller en svag grønlig farve. Grunden til at man kalder hvidvin for hvidvin, er for at markere modsætningen til rødvin. \n\nNår der skal produceres hvidvin, presser man druerne hvorefter man gærer druemosten ved enten at tilføje gær eller lade gæringen starte naturligt. Man starter gæringen naturligt ved at bruge de gærstammer, som er i miljøet. \n\nDer findes forskellige slags hvidvine, der kan både købes tørre, halvtørre, halvsøde eller søde vine, de fleste vine betegnes som tørre. For at en vin betegnes som tør, skal der være mindre end 3-4 gram ugæret sukker tilbage i vinen, hvorimod hvis der er mere end 4-5 gram sukker tilbage, betegner man vinen som halvtør. De helt søde hvidvine betegnes ofte som dessertvine. \n\nEn tør hvidvin passer godt til både skaldyr og fisk, samt ost. Det kan også passe godt til fjerkræ alt efter hvilket tilbehør der er til. En god tommelfingerregel kan være, at man skal vælge lys vin til lys sovs. '
          }
        />
        <Breadcrumbs type={'hvidvin'} />
      </Layout>
    </React.Fragment>
  );
}

export default WhiteWine;
